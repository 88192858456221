import { FC } from 'react'
import PlugLogo from 'components/project/PlugLogo'
import ReviewsIO from 'components/project/ReviewsIO'
import SeoContainer, { homeBreadcrumb } from 'components/util/SeoContainer'

type HomePageType = {}

const HomePage: FC<HomePageType> = ({}) => {
  return (
    <SeoContainer breadcrumb={homeBreadcrumb()}>
      <div className='bg-forward pt-10 px-10 position-relative pb-20'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 d-flex flex-column gap-4'>
              <PlugLogo />
              <h1 className='fs-display-1'>Coming soon</h1>
              <h1 className='fw-normal'>
                A cheap business address with 10-minute Setup &{' '}
                <strong>NO postage fees!</strong>
              </h1>
              <ReviewsIO />
            </div>
            <div className='col-md-4  d-flex flex-column gap-4'>
              <div
                className='d-md-block d-none position-absolute'
                style={{ bottom: -100, right: 100 }}
              >
                <img width={325} src={'/images/hero.png'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SeoContainer>
  )
}

export default HomePage
